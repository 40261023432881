<template>
  <div class="redBlueCenterAll">
    <div class="redBlueCenterFirst">
      <div class="redBlueCenterFirst1">
        <h2>御学防线</h2>
        <p>
          提供实时、互动的网络安全培训环境。平台旨在提高用户的安全意识和技能，适用于教育机构、企业内部培训等场合。为了保障数据安全，平台实现了数据加密存储和定期备份机制。此外，还有完备的安全策略和审计系统，包括
          JWT 认证流程、访问控制策略以及安全审计功能。
        </p>
      </div>
    </div>
    <div class="box">
      <ul class="tabs clearfix">
        <li
          class="tab-link"
          onclick="javascript:document.getElementById('proGs').scrollIntoView()"
        >
          产品概述
        </li>
        <li
          class="tab-link"
          onclick="javascript:document.getElementById('proNr').scrollIntoView()"
        >
          产品内容
        </li>
        <li
          class="tab-link"
          onclick="javascript:document.getElementById('proYss').scrollIntoView()"
        >
          产品优势
        </li>
        <li
          class="tab-link"
          onclick="javascript:document.getElementById('proGc').scrollIntoView()"
        >
          产品展示
        </li>
      </ul>
    </div>
    <div class="redBlueCenterSecond">
      <h1 id="proGs">产品概述</h1>
      <div class="redBlueCenterSecond1">
        <div class="redBlueCenterSecond1Left">
          <img src="../../../../assets/img/proctCenter/11.webp" alt="" />
        </div>
        <div class="redBlueCenterSecond1Right">
          <p>
            近年来，网络攻击日益加剧，网络安全事件多不胜数，国家信息基础设施遭受破坏，在政治、经济上造成严重影响，攻击主体也从个人演进成了组织。基于目前的网络安全态势，国家每年开展针对关键信息基础设施的实战攻防演习，以攻促防，通过演习行动发现问题，引导企事业单位进行网络安全建设。
          </p>
          <p>
            御学防线是一款专注于提供实时、互动的网络安全培训环境的创新性平台。通过结合先进的技术和教育理念，御学防线旨在为用户提供全面的网络安全培训体验，从而提高其安全意识和技能水平。无论是教育机构还是企业内部培训，御学防线都致力于为用户创造一个高效、安全、实用的学习环境。
          </p>
        </div>
      </div>
    </div>
    <div class="redBlueCenterThird">
      <h1 id="proNr">产品内容</h1>
      <div class="redBlueCenterThird1">
        <div class="redBlueCenterThird1Left">
          <h2>软件功能:</h2>
          <p>
            这款安全实训平台主要提供全面的网络安全培训解决方案。其核心功能包括用户管理系统，支持用户注册、登录以及个人信息的管理和权限设置。内容管理模块允许上传、编辑和管理教学视频、选择题题库以及PDF、PPT和DOC文件，便于构建丰富的教学资源库。此外，它还包括一个课程管理系统，用于创建和维护课程内容，并将视频、题目等素材关联到特定课程。实训内容管理部分则提供了实训课程的上传、更新以及实训任务的分配与监控功能。为了保障数据安全，平台实现了数据加密存储和定期备份机制。此外，还有完备的安全策略和审计系统，包括
            JWT 认证流程、访问控制策略以及安全审计功能。
          </p>
          <h2>软件特点</h2>
          <p>
            该安全实训平台在技术上采用了现代化的技术栈和架构设计。后端部分使用
            Go 语言和 Gin 框架，确保了处理效率和安全性。前端则采用 Vue 3 和
            Tdesign
            构建，实现了动态且互动性强的用户界面。平台的身份验证和授权通过 JWT
            (JSON Web Tokens) 实现，提高了系统的安全性。在数据库方面，选择了
            MySQL 8.0，结合 Gorm ORM 库进行高效的数据库操作。此外，系统采用了
            RESTful API
            设计，支持清晰、可扩展的接口交互。整体上，平台具有模块化、高效和安全的特点，支持灵活的部署方式，包括集成部署和分散部署，以适应不同规模的使用需求。最后，为了保证质量和持续性，平台还实施了完整的测试计划和持续集成/持续部署
            (CI/CD) 策略。
          </p>
        </div>
        <div class="redBlueCenterThird1Right">
          <img src="../../../../assets/img/proctCenter/12.webp" alt="" />
        </div>
      </div>
    </div>
    <div class="codeAuditThird">
      <h1 id="proYss">产品优势</h1>
      <div class="codeAuditThird1">
        <ul>
          <li>
            <div class="codeAuditThird1-1">
              <img
                src="https://www.boundaryx.com/wp-content/uploads/2022/05/11-1.webp"
                alt=""
              />
            </div>
            <div class="codeAuditThird1-2">
              <p class="codeAuditThird1-2-1">实时互动性</p>
              <span class="codeAuditThird1-2-2">
                御学防线采用实时互动的学习模式，用户能够随时随地参与网络安全培训，获得即时反馈，提高学习效果。
              </span>
            </div>
          </li>
          <li>
            <div class="codeAuditThird1-1">
              <img
                src="https://www.boundaryx.com/wp-content/uploads/2022/05/22-2.webp"
                alt=""
              />
            </div>
            <div class="codeAuditThird1-2">
              <p class="codeAuditThird1-2-1">全面培训内容</p>
              <span class="codeAuditThird1-2-2">
                平台提供多层次、多领域的网络安全培训课程，从基础知识到高级技能，满足不同用户的学习需求。
              </span>
            </div>
          </li>
          <li>
            <div class="codeAuditThird1-1">
              <img
                src="https://www.boundaryx.com/wp-content/uploads/2022/05/33-1.webp"
                alt=""
              />
            </div>
            <div class="codeAuditThird1-2">
              <p class="codeAuditThird1-2-1">安全保障机制</p>
              <span class="codeAuditThird1-2-2">
                为了确保用户数据的安全，御学防线实现了数据加密存储和定期备份机制，有效防范数据泄露风险
              </span>
            </div>
          </li>
          <li>
            <div class="codeAuditThird1-1">
              <img
                src="https://www.boundaryx.com/wp-content/uploads/2022/05/44.webp"
                alt=""
              />
            </div>
            <div class="codeAuditThird1-2">
              <p class="codeAuditThird1-2-1">完备的安全策略和审计系统</p>
              <span class="codeAuditThird1-2-2">
                平台采用JWT认证流程、访问控制策略以及安全审计功能，为用户提供可靠的安全保障，防范潜在的网络攻击。
              </span>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="redBlueCenterFourth" id="proGc">
      <h1>产品部分页面展示</h1>
      <div class="redBlueCenterFourthImgs">
        <img
          src="/img/proctCenter/12.jpg"
          alt=""
          @click="previewImage('/img/proctCenter/12.jpg')"
        />
        <img src="/img/proctCenter/13.jpg" alt=""  @click="previewImage('/img/proctCenter/13.jpg')"/>
        <img src="/img/proctCenter/15.jpg" alt=""  @click="previewImage('/img/proctCenter/15.jpg')"/>
        <img src="/img/proctCenter/16.jpg" alt=""  @click="previewImage('/img/proctCenter/16.jpg')"/>
        <img src="/img/proctCenter/17.jpg" alt=""  @click="previewImage('/img/proctCenter/17.jpg')"/>
        <img src="/img/proctCenter/18.jpg" alt=""  @click="previewImage('/img/proctCenter/18.jpg')"/>
        <img src="/img/proctCenter/19.jpg" alt=""  @click="previewImage('/img/proctCenter/19.jpg')"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {

    methods: {
      previewImage(src) {
        const preview = document.createElement("div");
        preview.style.position = "fixed";
        preview.style.top = "0";
        preview.style.left = "0";
        preview.style.width = "100%";
        preview.style.height = "100%";
        preview.style.backgroundColor = "rgba(0, 0, 0, 0.8)";
        preview.style.display = "flex";
        preview.style.justifyContent = "center";
        preview.style.alignItems = "center";
        preview.style.zIndex = "1000";

        const img = new Image();
        console.log(img);
        img.src =src;
        img.style.maxWidth = "80%";
        img.style.maxHeight = "80%";
        img.style.border = "5px solid white";

        // 图片加载错误处理
        img.onerror = () => {
          alert("图片加载失败");
          document.body.removeChild(preview);
        };

        // 点击预览区域关闭
        preview.addEventListener("click", () => {
          document.body.removeChild(preview);
        });

        preview.appendChild(img);
        document.body.appendChild(preview);
      },
    },
};
</script>

<style lang="less" scoped>
.redBlueCenterAll {
  width: 100%;
  .redBlueCenterFirst {
    background-image: url(../../../../assets/img/proctCenter/14.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    padding: 180px;
    .redBlueCenterFirst1 {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #fff;
      h2 {
        width: 34%;
        font-weight: bold;
        font-size: 48px;
        margin-bottom: 1%;
        text-align: left;
      }
      p {
        width: 34%;
        font-size: 20px;
        margin-top: 10px;
        color: #fff;
      }
    }
  }
  .box {
    margin: 0 auto;
  }
  .tabs {
    display: flex;
    justify-content: center;

    height: 95px;
    border-bottom: 1px solid #dadada;
  }
  .tabs li {
    float: left;
    // margin-right: 87px;
    list-style: none;
  }

  .tabs .tab-link {
    display: block;
    width: 270px;
    height: 65px;
    text-align: center;
    line-height: 65px;
    font-size: 20px;
    color: #000;
    text-decoration: none;
  }
  .tabs .tab-link:hover {
    cursor: pointer;
    height: 95px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 5px solid #3d4ed0;
    transition: 0.3s;
  }
  .redBlueCenterSecond {
    background-color: #f6f6f6;
    padding: 30px;
    h1 {
      text-align: center;
    }
    .redBlueCenterSecond1 {
      display: flex;
      justify-content: center;
      align-items: center;
      .redBlueCenterSecond1Left {
        margin-right: 30px;
      }
      .redBlueCenterSecond1Right {
        width: 32%;
        p {
          text-indent: 2rem;
          font-size: 16px;
          font-family: "微软雅黑";
          color: #51565d;
          margin-top: 10px;
          line-height: 1.8;
        }
      }
    }
  }
  .redBlueCenterThird {
    background-color: #f6f6f6;
    padding: 30px;
    margin-top: 40px;
    h1 {
      text-align: center;
    }
    .redBlueCenterThird1 {
      display: flex;
      justify-content: center;
      align-items: center;
      .redBlueCenterThird1Left {
        width: 32%;
        p {
          text-indent: 2rem;
          font-size: 16px;
          font-family: "微软雅黑";
          color: #51565d;
          margin-top: 10px;
          line-height: 1.8;
        }
        margin-right: 30px;
      }
      .redBlueCenterThird1Right {
      }
    }
  }
  .redBlueCenterFourth {
    margin-top: 40px;
    h1 {
      text-align: center;
    }
    .redBlueCenterFourthImgs {
      display: flex;
      img {
        width: 300px;
        border: 3px solid #ddd; /* 添加边框 */
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); /* 添加阴影 */
        border-radius: 10px; /* 圆角边框 */
        transition: 0.3s; /* 平滑过渡效果 */
        margin-bottom: 20px; /* 底部间距 */
        background-color: #f8f8f8; /* 背景色 */
        cursor: pointer; /* 鼠标悬停时的指针形状 */
        margin-left: 20px;
      }

      .redBlueCenterFourthImgs img:hover {
        border-color: #3d4ed0; /* 鼠标悬停时的边框颜色变化 */
        transform: scale(1.05); /* 鼠标悬停时图片略微放大 */
      }
    }
  }
  .codeAuditThird {
    width: 100%;
    margin-top: 40px;
    background: #f1f5ff;
    padding: 50px 0 20px 0;
    h1 {
      text-align: center;
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 40px;
      li {
        width: 35.5%;
        display: flex;
        padding: 20px;
        background-image: linear-gradient(180deg, #f4f7ff, #fcfcfe);
        border-radius: 12px;
        margin-top: 20px;
        margin-left: 20px;
        .codeAuditThird1-1 {
          img {
            width: 48px;
            height: 48px;
            margin-right: 20px;
          }
        }
        .codeAuditThird1-2 {
          .codeAuditThird1-2-1 {
            font-size: 24px;
            color: #1f2329;
          }
          .codeAuditThird1-2-2 {
            font-size: 16px;
            color: #51565d;
            margin-top: 20px;
            line-height: 26px;
          }
        }
      }
    }
  }
}
</style>
